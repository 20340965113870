import Vue from 'vue/dist/vue.esm.js'
import SearchForm from 'javascripts/search_form.vue'

const domain = document.getElementById('hp_key').getAttribute('domain')

new Vue({
  data: function () {
    return {
      domain: domain
    }
  },
  el: '#SearchForm',
  components: {
    'search_form': SearchForm
  },
  mounted: function () {},
  methods: {},
})
