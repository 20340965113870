<template>
  <div class='job_search touhan_search_form'>
    <div class='job_search_btm'>
      <ul class='search_tabs cf'>
        <li class='search_tabs_item'>
          <a v-bind:class="{ 'is-active': search.type != 'line' }" class="search_tabs_link chiiki_left_area" v-on:click="setSearchType(searchTypes[0])">
            <span class="tab_ttl">地域で検索する</span>
          </a>
        </li>
        <li class='search_tabs_item'>
          <a v-bind:class="{ 'is-active': search.type == 'line' }" class="search_tabs_link eki_right_area" v-on:click="setSearchType(searchTypes[1])">
            <span class="tab_ttl">路線・駅で検索する</span>
          </a>
        </li>
      </ul>
      <div v-bind:class="{ 'is-open': search.type != 'line' }" class='search_tabs_content' v-bind:style="{ display: (search.type != 'line' ? 'block' : 'none') }">
        <div class='tabs_content_wrap'>
          <div class='tabs_content_wrap2'>
            <div class='search_select' style="margin-bottom: 1em;">
              <span class="search_form_select">
                <select v-model="search.pref" v-on:change="searchDetail()">
                  <option value="">都道府県を選択する</option>
                  <option v-for="prefecture in prefectures" v-bind:value="prefecture.code">{{ prefecture.name }}</option>
                </select>
              </span>
            </div>
            <div class='search_accordion_area seatch_area_checkbox'>
              <span v-bind:class="(!isSetPrefecture ? 'disable_color' : '')"
                    v-on:click="isCities = !isCities;"
                    class='text span_txt' >
              市区町村を選択する</span>
              <div style="display: block;" class='accordion_search_cont' v-if="isCities">
                <div class='toggle'>
                  <div class='toggle_wrap cf'>
                    <div class="checkbox" style="display: inline-block" v-for="city in onChanges.cities">
                      <input type="checkbox" v-bind:value="city.code" v-bind:id="'municipality_' + city.code" class="css-checkbox" v-model="search.municipality"/>
                      <label v-bind:for="'municipality_' + city.code" class="css-label">&nbsp;{{ city.name }}</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-bind:class="{ 'is-active': search.type == 'line' }" class='search_tabs_content' v-bind:style="{ display: (search.type == 'line' ? 'block' : 'none') }">
        <div class='tabs_content_wrap'>
          <div class='tabs_content_wrap2'>
            <div class="station_selectbox_wrap">
              <div class="search_prefecture_select_box">
                <span class="search_form_select">
                  <select v-model="search.pref" v-on:change="searchDetail()">
                    <option value="">都道府県を選択する</option>
                    <option v-for="prefecture in prefectures" v-bind:value="prefecture.code">{{ prefecture.name }}</option>
                  </select>
                </span>
              </div>
              <div class="search_line_select_box">
                <span class="search_form_select">
                  <select v-model="search.line" v-on:change="searchStation()"
                          v-bind:disabled="!isSetPrefecture"
                          v-bind:class="(!isSetPrefecture ? 'disable_color' : '')">
                    <option value="">路線を選択する</option>
                    <option v-for="line in onChanges.lines" v-bind:value="line.line_key">{{ line.name }}</option>
                  </select>
                </span>
              </div>
            </div>

            <div style="clear: both;"/>
            <div class='search_accordion_area' v-if="isSetLine">
              <span v-bind:class='{ active: isStationsActive }' class='text span_txt' v-on:click="isStations = !isStations;isStationsActive = !isStationsActive">駅を選択する</span>
              <div style="display: block;" class='accordion_search_cont' v-if="isStations">
                <div class='toggle'>
                  <div class='toggle_wrap cf'>
                    <div class="checkbox" style="display: inline-block" v-for="station in onChanges.stations">
                      <input type="checkbox" v-bind:value="station.code" v-bind:id="'station_' + station.code" class="css-checkbox" v-model="search.station"/>
                      <label v-bind:for="'station_' + station.code" class="css-label">&nbsp;{{ station.name }}</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class='table_box'>
        <div class='input_area border_bottom'>
          <div v-bind:class='{ active: isCategoryActive }' class='th accordion_sp border_right' v-on:click="isCategory = !isCategory;isCategoryActive = !isCategoryActive">業種<span class='pc_hidden'>を指定する</span></div>
          <div class='td accordion_sp_td'>
            <div class='checkbox' style="display: inline-block;" v-for="businessCategory in businessCategoryCds" v-if="!isMobile || isCategory">
              <input
                class='css-checkbox contents_naiyou'
                type="checkbox"
                v-bind:id="'position_type_cds_' + businessCategory.value"
                v-bind:value="businessCategory.value"
                v-model="search.category"
              >
              <label class='css-label' v-bind:for="'position_type_cds_' + businessCategory.value">&nbsp;{{ businessCategory.text }}</label>
            </div>
          </div>
        </div>
        <!-- sp時の余白調整div -->
        <div class="search_input_area_space pc_hidden"></div>

        <div class='input_area border_bottom'>
          <div v-bind:class='{ active: isEmploymentActive }' class='th accordion_sp border_right' v-on:click="isEmployment = !isEmployment;isEmploymentActive = !isEmploymentActive">雇用形態<span class='pc_hidden'>を指定する</span></div>
          <div class='td accordion_sp_td'>
            <div class='checkbox' style="display: inline-block;" v-for="employmentType in employmentTypeCds" v-if="!isMobile || isEmployment">
              <input
                class='css-checkbox contents_naiyou'
                type="checkbox"
                v-bind:id="'employment_' + employmentType.value"
                v-bind:value="employmentType.value"
                v-model="search.employment"
              >
              <label class='css-label' v-bind:for="'employment_' + employmentType.value">{{ employmentType.text }}</label>
            </div>
          </div>
        </div>
        <!-- sp時の余白調整div -->
        <div class="search_input_area_space pc_hidden"></div>

        <div class='input_area' v-show="isDetailConditionAreaActive == true">
          <div v-bind:class='{ active: isDetailsActive }' class='th accordion_sp border_right search_detail_width' v-on:click="isDetails = !isDetails;isDetailsActive = !isDetailsActive">こだわり条件<span class='pc_hidden'>を指定する</span></div>
          <div class='label_box_sub_table' v-for="conditions in detailConditions" v-if="!isMobile || isDetails">
            <div class='label_th' v-show="showLabel == true">{{ conditions.name }}</div>
            <div class='td accordion_sp_td'>
              <div class='checkbox' style="display: inline-block;" v-for="condition in conditions.kinds" v-if="!isMobile || isDetails">
                <input
                  class='css-checkbox'
                  type="checkbox"
                  v-bind:id="`${condition.value}_` + condition.value"
                  v-bind:value="condition.value"
                  v-model="search.condition"
                >
                <label class='css-label' v-bind:for="`${condition.value}_` + condition.value">{{ condition.text }}</label>
              </div>
            </div>
          </div>
        </div>
        <div v-show='isMobile == false && showDetailConditionBar == true' class='search_accordion_area' 
          style="margin-top:0px;margin-bottom:0px" v-on:click="isDetailConditionAreaActive = !isDetailConditionAreaActive">
          <span v-bind:class='{ active: isDetailConditionAreaActive }' class="text span_txt" style="text-align: center;">こだわり条件を指定する</span>
        </div>
      </div>
      <div class='btn_wrap'>
        <button class='submit_btn' v-on:click="execSearch()">選択した条件で検索する</button>
      </div>
    </div>
  </div>
</template>

<script>
  import $ from 'jquery'
  import Cookies from 'js-cookie'
  import agentAPI from 'javascripts/utils/agent_api'

  export default {
    props: {
      domain: { require: true, type: String }
    },
    data: function () {
      return {
        formOptions: {},
        search: {
          pref: '',
          municipality: [],
          line: '', station: [],
          category: [],
          employment: [], condition: [], order: '01', type: 'area'
          // orderはパラメータを与えない限りCookie値から呼び出し
        },
        searchTypes: ['area', 'line'], // 路線検索or地域検索
        onChanges: {
          cities: [],
          lines: [],
          stations: []
        },
        isCities: false,
        isStations: false,
        isEmployment: false,
        isCategory: false,
        isDetails: true,
        isStationsActive: false,
        isCategoryActive: false,
        isEmploymentActive: false,
        isDetailsActive: false,
        isDetailConditionAreaActive: true,
        locationPath: '/search/result',
        cookieKey: '',
        setupSearchOptionsUrl: '',
        showLabel: false,
        showDetailConditionBar: false
      }
    },
    mounted: function() {
      if (this.domain === 'pharmacist') {
        const location_key_element = document.getElementById('location_key')
        if(location_key_element) {
          this.locationPath = '/feature/result/' + location_key_element.getAttribute('location_key')
        }
        this.setupSearchOptionsUrl = '/api/v1/code/setup_pharmacist_search_options'
        this.showLabel = true
        this.showDetailConditionBar = true
        if (this.isMobile == false) {
          this.isDetailConditionAreaActive = false
        }
      } else {
        // 登販AGENTで特集ページ作成があった場合は、検索実行した時にsearch/resultのままになってしまうので留意（薬キャリAGENT参考）
        this.locationPath = '/touhan/search/result'
        this.setupSearchOptionsUrl = '/api/v1/code/setup_touhan_search_options'
      }
      this.cookieKey = '_' + this.domain + '_conditions'
      if(Cookies.get(this.cookieKey)) {
        this.setupCookie(JSON.parse(Cookies.get(this.cookieKey)))
        this.restoreConditions()
      }
      this.setupOption()
      this.isEmployment = !this.isMobile
      this.isCategory = !this.isMobile
      this.isDetails = !this.isMobile
    },
    watch: {},
    computed: {
      isLoadOption(){
        return !this.formOptions || !this.formOptions.codes
      },
      prefectures() {
        return (!this.formOptions || !this.formOptions.prefectures ? {} : this.formOptions.prefectures)
      },
      businessCategoryCds() {
        return (this.isLoadOption ? [] : this.formOptions.codes.business_category)
      },
      employmentTypeCds() {
        return (this.isLoadOption ? [] : this.formOptions.codes.employment_type)
      },
      detailConditions() {
        return (this.isLoadOption ? [] : this.formOptions.codes.detail_conditions)
      },
      isSetPrefecture(){
        return (this.search.pref ? true : false)
      },
      isSetLine(){
        return (this.search.line ? true : false)
      },
      isMobile(){
        return ($(window).width() < 640 ? true : false)
      }
    },
    methods: {
      // mounted
      setupCookie(selectedValues){ // Cookieによる前回検索autoLoad
        for (let key in selectedValues) {
          this.search[key] = selectedValues[key]
        }
      },
      setupOption(){ // 初期ロード時の選択値
        agentAPI.get(this.setupSearchOptionsUrl).then((response) => {
          this.formOptions = response.data
        }, (error) => { console.log(error) });
      },
      restoreConditions(){ // 前回検索の条件から選択リストを復元する(選択値は設定済み)
        if(this.search.pref) { // 都道府県選択済み
          this.loadCitiesData(this.search.pref)
          this.loadData('lines', this.search.pref)
        }
        if(this.search.line) { // 路線選択済み
          this.loadData('stations', this.search.line)
        }
      },
      // before & after Loaded
      loadData(collect, params){ // onChange実行時にSvrよりロード
        agentAPI.get('/api/v1/code/collect_' + collect + '/' + params).then((response) => {
          this.onChanges[collect] = response.data
        }, (error) => { console.log(error) });
      },
      loadCitiesData(params){
        agentAPI.get('/api/v1/code/setup_cities/' + params).then((response) => {
          this.onChanges['cities'] = response.data
        }, (error) => { console.log(error) });
      },
      // after loaded
      setSearchType(type) {
        this.search.type = type
      },
      searchDetail(){ // 都道府県操作選択時(画面ロード時を除く)
        this.onChanges.cities = []
        this.onChanges.lines = []
        this.onChanges.stations = []
        this.search.municipality = []
        this.search.line = ''
        this.search.station = []
        if(!this.search.pref) { return true }
        this.loadCitiesData(this.search.pref)
        this.loadData('lines', this.search.pref)
      },
      searchStation() { // 路線操作選択時(画面ロード時を除く)
        this.onChanges.stations = []
        this.search.station = []
        if(!this.search.line) { return true }
        this.loadData('stations', this.search.line)
      },
      setupParamsString(columns, column_separator, value_separator) { //　パラメータを積み上げる
        let params = []
        for(let index = 0 ; index < columns.length ; index++ ){
          let param = ''
          if (columns[index]=='type') { // 特殊タイプの処理
            param = (this.search[columns[index]] == 'line' ?  'type=line' : '')
          } else if (Array.isArray(this.search[columns[index]]) && this.search[columns[index]].length > 0){ // 配列タイプの処理
            let values = this.search[columns[index]].sort().join('*')
            param = columns[index] + value_separator + values
          } else if(this.search[columns[index]] && this.search[columns[index]].toString().length > 0) { // 文字列タイプの処理
            param = columns[index] + value_separator + this.search[columns[index]]
          }
          params.push(param)
        }
        return params.filter(v => v).join(column_separator)
      },
      searchColumnType(){ // 選択タイプにおけるパラメータ項目の選別
        return ( this.search.type == 'line' ? ['pref', 'line', 'station', 'category'] : ['pref', 'municipality', 'category'])
      },
      execSearch(){ // urlを生成してlocation_hrefを実行(検索)
        let columns = this.searchColumnType()
        let path_params = this.setupParamsString(columns, '/', '_')
        let params = this.setupParamsString(['type', 'employment', 'condition', 'order'], '&', '=')
        if(path_params) { path_params += '/' }
        window.location.href = this.locationPath + '/' + path_params + '?' + params
      }
    }
  }
</script>
